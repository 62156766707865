$color1 : #2961ea;
$color2 : #f5f5f5;
$text : #555555;
$highlight1 : #01d5a1;
$highlight2 : #b388dd;
$highlight3 : #ff8750;
$highlight4 : #01AD9B;

.text-center {
	text-align: center;
}

.cf {
	*zoom: 1;
	&:before, &:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}



body {
  //font-family: 'Roboto', sans-serif;
  color: $text;
	.wrapperLeft {
		max-width: 450px;
		margin: 0 auto;
		h1 {
			margin-top: 50px;
			margin-bottom: 50px;
			text-transform: uppercase;
			font-size: 1.5em;
		}
		.speechbubble {
			background-color: $color2;
			color: $text;
			font-size: .9em;
			line-height: 1.75;
			padding: 15px 25px;
			margin-bottom: 75px;
      cursor: default;

			//  Border and arrow left
			&:nth-child(1n) {
				border-left: 5px solid;
			}
			&:nth-child(1n):after {
				content: '';
				margin-top: -30px;
				padding-top: 0px;
				position: relative;
				bottom: -45px;
				left: 250px;
				border-width: 30px 0 0 30px;
				border-style: solid;
				border-color: $color2 transparent;
				display: block;
				width: 0;
			}
			//  Border and arrow right

			// Randomize every 4th bubble's border color
			&:nth-child(4n+1) {
				//border-color: hsla(random(365), random(365), 50, 1);
				border-color: $highlight1;
			}

			// Quotation symbols
			p:before {
				font-family: Georgia;
				font-size: 40px;
		    line-height: 0;
		    display: inline-block;
				display: -webkit-inline-box;
			}
			.username {
				display: inline;
				font-style: italic;
				float: right;
				&:before {
					content: '- ';
				}
			}
		}
	}

	.wrapperRight {
		max-width: 450px;
		margin: 0 auto;
		h1 {
			margin-top: 50px;
			margin-bottom: 50px;
			text-transform: uppercase;
			font-size: 1.5em;
		}
		.speechbubble {
			background-color: $color2;
			color: $text;
			font-size: .9em;
			line-height: 1.75;
			padding: 15px 25px;
			margin-bottom: 75px;
      cursor: default;
			//  Border and arrow right
			&:nth-child(2n+1) {
				border-right: 5px solid;
			}
			&:nth-child(2n+1):after {
				content: '';
				margin-top: -30px;
				padding-top: 0px;
				position: relative;
				bottom: 225px;
				left: 10px;
				border-width: 0 30px 30px 0;
				border-style: solid;
				border-color: $color2 transparent;
				display: block;
				width: 0;
			}


			// Randomize every 4th bubble's border color
			&:nth-child(4n+1) {
				//border-color: hsla(random(365), random(365), 50, 1);
				border-color: $highlight1;
			}

			// Quotation symbols
			p:before {
				font-family: Georgia;
				font-size: 40px;
		    line-height: 0;
		    display: inline-block;
				display: -webkit-inline-box;
			}
			.username {
				display: inline;
				font-style: italic;
				float: right;
				&:before {
					content: '- ';
				}
			}
		}
	}
}
