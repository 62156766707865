.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40%;
	right:0px;
	background-color:#25d366;
	color:#FFF;
	border-radius: 10px 0px 0px 10px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.float:hover{
	background-color: #1ee267;
	color: #ffffff;
}

.my-float{
	margin-top:16px;
}