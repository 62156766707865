.custom-nav-items a {
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    color: #555555;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    margin: 10px 10px 0 10px;
}

.custom-login-button {
    text-transform: uppercase;
    margin-top: -10px;
}

.custom-header {
    background-color: white;
    box-shadow: 0px 3px 3px rgba(136, 136, 136, 0.27);
}

.custom-divider{
    margin: 0 0 0 0;
}
.ant-timeline-item-tail{
    border-left: #01d5a1;
}

@media screen and (max-width: 960px) {
    .custom-nav-items a {
        font-family: 'Lato', sans-serif;
        font-size: 18px;
        color: #555555;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 400;
        margin: 10px 10px 0 10px;
        text-align: center;
    }

    .custom-login-button {
        margin-top: 10px;
        width: 94%;
    }


}


