.custom-navbar-logo{
    width: 250px;
    background-color:#ffffff
}


@media only screen and (max-width: 600px) {
    .custom-navbar-logo{
        width: 250px;
        margin-left: 20px;
    }

    .custom-navbar{
        margin-right: 30px;
    }

}
