
.custom-title {
  h1, h2, h3, h4, h5, h6 {
    margin: 50px 0 50px 0;
    font-family: 'Lato', sans-serif;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 300;
  }
}

.custom-subtitle {
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Lato', sans-serif;
    text-decoration: none;
    font-weight: 500;
  }
}

.custom-paragraph {
  p {
    font-size: 18px;
    font-family: 'Lato', sans-serif;
    text-decoration: none;
    font-weight: 300;
    text-align: left;
  }
}

.custom-paragraph-2 {
  p {
    font-size: 20px;
    font-family: 'Lato', sans-serif;
    text-decoration: none;
    font-weight: 300;
  }
}

.custom-paragraph-3 {
  p {
    font-size: 20px;
    font-family: 'Lato', sans-serif;
    text-decoration: none;
    font-weight: 500;
  }
}

//.custom_subtitle {
//  font-size: 30px;
//
//}
//
//.custom_subtitle_2 {
//  font-size: 22px;
//  font-family: 'Lato', sans-serif;
//  text-decoration: none;
//  text-transform: uppercase;
//  font-weight: 500;
//}
//
//.custom_text {
//
//}
//
//.custom_text_2 {
//  font-size: 18px;
//  font-family: 'Lato', sans-serif;
//  text-decoration: none;
//  font-weight: 300;
//  text-transform: uppercase;
//}
//
//.custom_text_3 p {
//  font-size: 18px;
//  font-family: 'Lato', sans-serif;
//  text-decoration: none;
//  font-weight: 300;
//  text-align: justify;
//}
//
//.custom_text_chatbot {
//  font-size: 16px;
//  font-family: 'Lato', sans-serif;
//  text-decoration: none;
//  font-weight: 300;
//}
//
//.custom_img {
//  width: 200px;
//  margin-top: -50px;
//}
//
//.custom_img_2 {
//  width: 200px;
//  margin: 20px 0 20px 0;
//}
//
//.custom_img_3 {
//  width: 300px;
//  margin: 0 auto 0 auto;
//}
//
//.custom_img_4 {
//  width: 200px;
//  margin: 0 auto 0 auto;
//}

/*.custom_subtitle_2 {*/
/*    font-size: 30px;*/
/*    text-align: center;*/
/*    margin: 50px 0 50px 0;*/
/*    font-family: 'Lato', sans-serif;*/
/*    text-decoration: none;*/
/*    text-transform: uppercase;*/
/*    font-weight: 400;*/
/*}*/

//.custom-text-1 {
//  font-size: 18px;
//  color: white;
//}
//
//.custom_font {
//}
//
//}
