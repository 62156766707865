.custom-dropdown-menu {
  width: 200px;
  position: absolute;
  top: 46px;
  list-style: none;
  text-align: start;
}

.custom-dropdown-menu li {
  background: #ffffff;
  cursor: pointer;
}

.custom-dropdown-menu li:hover {
  background: #f3f3f3;
}

.custom-dropdown-menu.clicked {
  display: none;
}

.custom-dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #555555;
  padding: 16px;
}

.custom-dropdown-link:hover {
  color: #01d5a1;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
