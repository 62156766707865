.decorated {
    overflow: hidden;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.decorated > span {
    position: relative;
    display: inline-block;
}

.decorated > span:before, .decorated > span:after {
    content: '';
    position: absolute;
    top: 50%;
    border-bottom: 2px solid;
    width: 100vw;
    margin: 0 20px;
}

.decorated > span:before {
    right: 100%;
}

.decorated > span:after {
    left: 100%;
}

.decorated {
    overflow: hidden;
    text-align: center;
}

.decorated > span {
    position: relative;
    display: inline-block;
}

.decorated > span:before, .decorated > span:after {
    content: '';
    position: absolute;
    top: 50%;
    border-bottom: 2px solid;
    width: 100vw;
    margin: 0 20px;
}

.decorated > span:before {
    right: 100%;
}

.decorated > span:after {
    left: 100%;
}
