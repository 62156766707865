.carousel-button {
    font-size: 60px;
    color: #ffffff;
    position: absolute;
    z-index: 1;
    margin-top: 150px;
}

.carousel-button-container {
    position: absolute;
    z-index: 1;
    width: 100px;
    min-height: 400px;
    background-color: rgba(0, 0, 0, 0.07);
}

.carousel-container {
    position: relative;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 60px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
