@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");


h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
}

.TextCustom {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 1rem;
}

.BackgroundImageCustom {
    background-image: url("./../img/bg-terms.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    visibility: visible;
    opacity: 1;
}
